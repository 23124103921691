import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./Footer.style";
import { useAuth } from "../../context/context";
import { LangObjectName } from "../../common/enums/laguages";
import { appStrings } from "../../common/services/i18n";
import colors from "../../common/styles/colors";
import { contactDetails } from "../../common/constants/contactDetails";

const Footer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const authStore = useAuth();

  const getCurrentLanguageCode = () => {
    if (authStore?.getCurrentLanguageCode()) {
      return authStore?.getCurrentLanguageCode();
    }
    return "en";
  };

  const getCustomerServicesLink = () => {
    const langCode = getCurrentLanguageCode();
    const langObject = LangObjectName[langCode];
    return appStrings[langObject]["customerServicesLink"];
  };

  const getTermsAndConditionsLink = () => {
    const langCode = getCurrentLanguageCode();
    const langObject = LangObjectName[langCode];
    return appStrings[langObject]["termsAndConditionsLink"];
  };

  const getPrivacyPolicyLink = () => {
    const langCode = getCurrentLanguageCode();
    const langObject = LangObjectName[langCode];
    return appStrings[langObject]["privacyPolicyLink"];
  };

  return (
    <Grid container className={classes.footerContainer}>
      <Grid item>
        <Typography className={classes.footerTitle}>
          {t("translation:footer.title1")}
        </Typography>
        <Typography className={classes.footerItem}>
          <a
            href={getCustomerServicesLink()}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: colors.darkerGreyColor }}
          >
            {t("translation:footer.item1_1")}
          </a>
        </Typography>
        <Typography className={classes.footerHiddenItem}>{"-"}</Typography>
        <Typography className={classes.footerHiddenItem}>{"-"}</Typography>
        <Typography className={classes.footerItem}>
          {t("translation:footer.item1_2") + " 1.40"}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.footerTitle}>
          {t("translation:footer.title2")}
        </Typography>
        <Typography className={classes.footerItem}>
          <a
            href={getTermsAndConditionsLink()}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: colors.darkerGreyColor }}
          >
            {t("translation:footer.item2_1")}
          </a>
        </Typography>
        <Typography className={classes.footerItem}>
          <a
            href={getPrivacyPolicyLink()}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: colors.darkerGreyColor }}
          >
            {t("translation:footer.item2_2")}
          </a>
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.footerTitle}>
          {t("translation:footer.title3")}
        </Typography>
        <Typography className={classes.footerItem}>
          {contactDetails[getCurrentLanguageCode()]["item3_1"]}
        </Typography>
        <Typography className={classes.footerItem}>
          {contactDetails[getCurrentLanguageCode()]["item3_2"]}
        </Typography>
        <Typography className={classes.footerItem}>
          {contactDetails[getCurrentLanguageCode()]["item3_3"]}
        </Typography>
        <Typography className={classes.footerItem}>
          {contactDetails[getCurrentLanguageCode()]["item3_4"]}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
