import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "./colors";
import { CSSProperties } from "react";

export const commonHeaderThree: CSSProperties = {
  fontSize: "22px !important",
  lineHeight: "26px !important",
  fontFamily: "VistaSansReg !important",
  fontWeight: 700,
  paddingBottom: "24px !important",
};

export const commonHeaderTwo: CSSProperties = {
  fontSize: "32px !important",
  lineHeight: "26px !important",
  fontFamily: "VistaSansReg !important",
  fontWeight: 700,
  paddingBottom: "24px !important",
};

export const commonParagraph: CSSProperties = {
  fontSize: "16px !important",
  lineHeight: "28px !important",
  fontFamily: "Roboto, sans-serif !important",
};

export const commonParagraph14px: CSSProperties = {
  fontSize: "14px !important",
  lineHeight: "16px !important",
  fontFamily: "Roboto !important",
};

export const commonParagraph12px: CSSProperties = {
  fontSize: "12px !important",
  lineHeight: "14px !important",
  fontFamily: "Roboto !important",
};

export const paragraph14pxHumanist: CSSProperties = {
  fontSize: "14px !important",
  lineHeight: "16px !important",
  fontFamily: "humanistBD !important",
};

export const mainHeader: CSSProperties = {
  fontSize: "44px !important",
  fontFamily: "VistaSansReg !important",
  fontWeight: 700,
  lineHeight: "47px !important",
  color: colors.blackColor,
};

export const primaryHeader: CSSProperties = {
  fontSize: "44px !important",
  fontFamily: "VistaSansReg !important",
  fontWeight: 700,
  lineHeight: "47px !important",
};

const useTypographyStyles = makeStyles(() =>
  createStyles({
    root: {},
    mainHeader: {
      fontSize: "44px !important",
      lineHeight: "47px !important",
      color: colors.blackColor,
      fontFamily: "humanistBD !important",
      paddingBottom: "40px !important",
    },
    headerThreeBlack: {
      color: colors.primaryColor,
      ...commonHeaderThree,
      wordBreak: "break-word"
    },
    headerTwoBlack: {
      color: colors.primaryColor,
      ...commonHeaderTwo,
    },
    headerThreeLight: {
      color: colors.secondaryColor,
      ...commonHeaderThree,
    },
    lineNoWrap: {
      whiteSpace: "nowrap",
    },
    paragraphPrimary: {
      color: colors.primaryColor,
      ...commonParagraph,
    },
    paragraphBlack: {
      color: colors.darkGreyColor,
      ...commonParagraph,
    },
    paragraphBlack14px: {
      color: colors.darkGreyColor,
      lineHeight: "26px !important",
      fontFamily: "Roboto, sans-serif !important",
      fontSize: "14px !important",
    },
    paragraphLight: {
      color: colors.veryLightGreyColor,
      ...commonParagraph,
    },
    tabTitles: {
      fontSize: "24px !important",
      lineHeight: "26px !important",
      color: colors.primaryColor,
      fontFamily: "VistaSansReg !important",
      "font-weight": "700 !important",
    },
    modalLabel: {
      color: colors.primaryColor,
      ...commonParagraph14px,
    },
    modalLabelCursorDefault: {
      color: colors.primaryColor,
      cursor: "default",
      ...commonParagraph14px,
    },
    modalContent: {
      color: colors.grey,
      fontSize: "12px !important",
      lineHeight: "16px !important",
      fontFamily: "Roboto !important",
      paddingTop: "4px !important",
    },
    modalContentCursorDefault: {
      color: colors.grey,
      cursor: "default",
      fontSize: "12px !important",
      lineHeight: "16px !important",
      fontFamily: "Roboto !important",
      paddingTop: "4px !important",
    },
  })
);

export default useTypographyStyles;
