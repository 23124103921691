export enum ZonesEnum {
  Zone1 = 1,
  Zone2,
  Zone3,
  Zone4,
  Zone5,
}

export enum PirEnum {
  ZoneA,
  ZoneB,
  ZoneC,
}

export enum SensorError {
  PushButtonStuck = 1,
  MissingShortAdr = 2,
  InitFailed = 4,
  ControlGearFailure = 8,
  LampFailure = 16,
  BusVoltageToLow = 32,
  BusVoltageOverload = 64,
  BusShorted = 128,
  RTCTimeNotSet = 256,
}

export enum CfgSystemCommonFunc0 {
  CFG_INT_RELAY_STDBY_MIN = 1,
  CFG_INT_RELAY_HVAC = 2,
  CFG_INT_RELAY_LIGHTING = 4,
  RESERVED3 = 8,
  RESERVED4 = 16,
  RESERVED5 = 32,
  RESERVED6 = 64,
  RESERVED7 = 128,
}

export enum CfgSystemCommonFunc1 {
  RESERVED = 1,
  CFG_YGM_INDICATION = 2,
  CFG_WALKTEST_ACTIVE = 4,
  CFG_WIRELESS_PB_ENABLED = 8,
  CFG_ECO_ENABLED = 16,
  CFG_WIRELESS_SLAVE_ENABLED = 32,
  CFG_WIRELESS_LINK_ENABLED = 64,
  CFG_WIRELESS_NEIGH_SLAVE = 128,
}

export enum CfgZoneCommonFunc1 {
  CFG_DLC_ACTIVE = 1,
  CFG_OVEREXP_ACTIVE = 2,
  CFG_8HOUR_ACTIVE = 4,
  RESERVED3 = 8,
  CFG_MANUAL_OFF = 16,
  CFG_MANUAL_ON = 32,
  CFG_AUTO_ON = 64,
  RESERVED7 = 128,
}

export enum EnZoneCommonFunc1 {
  SYSTEM_DLC = 1,
  SYSTEM_OVEREXP = 2,
  SYSTEM_8HOUR = 4,
  SYSTEM_CONSTON = 8,
  SYSTEM_MANUAL_OFF = 16,
  SYSTEM_MANUAL_ON = 32,
  SYSTEM_AUTO_ON = 64,
  RESERVED = 128,
}

export enum EnSystemFunc1 {
  SYSTEM_WIREDSLAVES = 1,
  SYSTEM_SWUPDATEINSTALLER = 2,
  SYSTEM_YGM_INDICATION = 4,
  SYSTEM_WIRLESSLINK = 8,
  SYSTEM_WIRELESSPUSHB = 16,
  SYSTEM_WIRELESSSLAVE = 32,
  SYSTEM_SETSENS = 64,
  SYSTEM_WALK = 128,
}

export enum EnSystemFunc2 {
  RESERVED = 1,
  SYSTEM_RELAY_NEIGH_SLAVE = 2,
  SYSTEM_MULTIZONE = 4,
  SYSTEM_AUTOCONFIG = 8,
  SYSTEM_DALI_AUTO_Z1 = 16,
  SYSTEM_DALI_BC = 32,
  SYSTEM_CMDMONITOR = 64,
  SYSTEM_BURNIN = 128,
}

export enum EnSystemApp7 {
  APP7_DC_FUNCTIONS_TW_HUMANCENTRICLIGHTING_PREDIFINED_CURVE1 = 1,
  APP7_DC_FUNCTIONS_TW_HUMANCENTRICLIGHTING_PREDIFINED_CURVE2 = 2,
  APP7_DC_FUNCTIONS_TW_HUMAN_CENTRIC_LIGHTING = 4,
  APP7_FUNCTIONS_EXTERNAL_TRIGGER = 8,
  APP7_FUNCTIONS_LIGHT_SCENE = 16,
  APP7_FUNCTIONS_NIGHT_MODE = 32,
  APP7_FUNCTIONS_TUNABLE_WHITE = 64,
  APP7_DALICOMFORT_BUTTONS_DALI2_PUSH_BUTTONS = 128
}

export enum CfgSystemDaliFunc2 {
  RESERVED0 = 1,
  RESERVED1 = 2,
  CFG_BURNIN_ACTIVE = 4,
  CFG_DALI_CONFIGURED = 8,
  RESERVED4 = 16,
  CFG_USR_DYN_SP_ACTIVE = 32,
  CFG_TW_DYNAMIC_0 = 64,
  CFG_TW_DYNAMIC_1 = 128,
}

export enum PushButtonFunction {
  NO_FUNCTION = 0,
  ONLY_ON = 1,
  ON_OFF = 2,
  ONLY_OFF = 3,
  RESERVED1 = 4,
  TW_WARMER_COOLER = 5,
  TW_WARMER = 6,
  TW_COOLER = 7,
  CALL_SCENE = 8,
  FDOOR_OPEN = 9,
  FDOOR_CLOSED = 10,
  TW1 = 11,
  TW2 = 12,
  TW3 = 13,
  TW4 = 14,
  RESERVED3 = 15,
  RESERVED4 = 16,
  RESERVED5 = 17,
  RESERVED6 = 18,
  AUTO = 19,
  RESERVED7 = 20,
  RESERVED8 = 21,
  RESERVED9 = 22,
  RESERVED10 = 23,
  TWO_LEVEL_ACTIVE = 24,
  DLC_ACTIVE = 25,
  RESERVED11 = 26,
  LEVEL = 27,
  RESERVED12 = 28,
}

export enum PushButtonCfgInfo {
  SWITCH = 1,
  ZONE_1_CHANNEL_1 = 2,
  ZONE_2_CHANNEL_2 = 4,
  ZONE_3 = 8,
  ZONE_4 = 16,
  ZONE_5_INTERNAL_RELAY = 32,
  MUZ = 64,
  RESERVED7 = 128,
}

export enum DaliPushButton {
  T00 = 0,
  T01 = 1,
  T02 = 2,
  T03 = 3,
  T10 = 4,
  T11 = 5,
  T12 = 6,
  T13 = 7,
  T20 = 8,
  T21 = 9,
  T22 = 10,
  T23 = 11,
  T30 = 12,
  T31 = 13,
  T32 = 14,
  T33 = 15,
}

export enum CfgZoneType
{
  NOT_USED = 0,
  DLZ = 1,
  SEZ = 2,
  MUZ = 3,
  HVAC = 4
}

export var LightLevelArray = [0, 84, 110, 126, 136, 144, 151, 156, 161, 166, 170, 173, 176, 179, 182, 184, 187, 189, 191, 193, 195, 197, 198, 200, 202, 203, 205, 
  206, 207, 209, 210, 211, 212, 213, 214, 216, 217, 218, 219, 220, 221, 222, 222, 223, 224, 225, 226, 226, 227, 228, 229, 229, 230, 
  231, 231, 232, 233, 233, 234, 235, 235, 236, 236, 237, 238, 238, 239, 239, 240, 240, 241, 241, 242, 242, 243, 243, 244, 244,
  245, 245, 246, 246, 247, 247, 247, 248, 248, 249, 249, 249, 250, 250, 251, 251, 251, 252, 252, 253, 253, 253, 254 ];