import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import {
  Grid,
  InputBase,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Modal,
  Button,
  Checkbox,
} from "@material-ui/core";
import AddTemplate from "./components/AddTemplate";
import DetectorDetails from "./components/DetectorDetails";
import {
  getDetectorByMacAddress,
  markAsFavorite,
} from "../../data-services/Detectors/DetectorsService";
import { IDetectorModel } from "../../models/DetectorModel";
import TextMaskCustom from "../../common/components/TextMask/TextMask";
import ExportModal from "../../common/components/ExportModal";
import DetectorHeader from "./components/DetectorsHeader/DetectorsHeader";
import DetectorsNoSearch from "./components/DetectorsNoSearch/DetectorsNoSearch";
import { exportDetectorsPDF } from "../../common/components/Export/ExportPDF";
import { exportDetectorsExcel } from "../..//common/components/Export/ExportExcel";
import Export from "../../common/components/Actions/Export";
import { useAuth } from "../../context/context";
import useTypographyStyles from "../../common/styles/typography";
import useButtonStyles from "../../common/styles/buttonStyles";
import useStyles from "./Detector.style";
import { getDetectorTypeFormatted } from "../../data-services/Templates/TemplateMapper";
import { getLocalizedRoute } from "../../common/services/i18n/utils/routeLocalization";
import { useHistory, useLocation } from "react-router-dom";
import { AppRoute } from "../../common/constants/routes";
import { useIntl } from "react-intl";
import LoadingSpinner from "../../LoadingSpinner";

const Detector: React.FC = () => {
  const appStore = useAuth();
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const buttonStyles = useButtonStyles();
  const { t } = useTranslation(["translation"]);
  const cellAlignment = "left";
  const [detector, setDetector] = useState<IDetectorModel | undefined>(
    undefined
  );
  const [detectors, setDetectors] = useState<IDetectorModel[]>([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [error, setError] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteName, setFavoriteName] = useState("");
  const [openAddFavoriteModal, setOpenAddFavoriteModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [showNote, setShowNote] = useState(
    !appStore?.userHistory?.isDetectorNotificationSeen
  );
  const [openExportModal, setOpenExportModal] = useState(false);
  const [selectedDetectors, setSelectedDetectors] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const timeZone = moment.tz.guess();
  const macAddressSuffix = "10:B9:F7:";
  const { formatMessage, locale } = useIntl();

  let history = useHistory();

  const useQuery = () => {
      return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  const handleNoteInfo = () => {
    setShowNote(false);
    appStore?.updateUserHistory(appStore.userHistory);
  };

  const handleOpenAddFavoriteModal = (detector: IDetectorModel) => {
    setOpenAddFavoriteModal(true);
    setDetector(detector);
  };

  const handleCloseAddFavoriteModal = () => {
    setOpenAddFavoriteModal(false);
  };

  const handleOpenDetailsModal = (detector: IDetectorModel) => {
    setOpenDetailsModal(true);
    setDetector(detector);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
  };

  const getDetector = async (macQueryParam: string = "") => {
    let macInput = macAddressSuffix + searchedValue;
    let toSearch = searchedValue;
    if (macQueryParam.length > 1) {
      macInput = macAddressSuffix + macQueryParam;
      toSearch = macQueryParam;
    }
    setIsFetching(true);

    const { data: detectors = [], status } = await getDetectorByMacAddress(
      macInput.split(":").join("")
    );

    if (status === "success") {
      setDetectors(detectors);
      history.push(`${getLocalizedRoute(AppRoute.Detector, locale, formatMessage)}?mac=${encodeURIComponent(toSearch)}`);
    } else {
      setDetectors([]);
    }

    if (status === "error") {
      setError(true);
    }

    setIsFetching(false);
  };

  const createTemplate = async () => {
    if (!!detector) {
      const data = {
        sensorSettingId: detector.sensorSettingId,
        favoriteName: favoriteName,
      };
      const { status } = await markAsFavorite(data);

      if (status === "success") {
        getDetector();
        // if (appStore) {
        //   appStore.countMyNewTemplates();
        // }
      }

      handleCloseAddFavoriteModal();
    }
  };

  const handleOpenExportModal = () => {
    if (detectors.length > 0) {
      setOpenExportModal(true);
    }
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
  };

  const getSelectedProjectNames = () => {
    if (detector) {
      return detector.projectName;
    } else {
      return detectors
        .filter((detector) =>
          selectedDetectors.includes(detector.sensorSettingId)
        )
        .map((detector) => detector.projectName)
        .join(", ");
    }
  };

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    detector: IDetectorModel
  ) => {
    if (event.target.checked) {
      setSelectedDetectors(selectedDetectors.concat(detector.sensorSettingId));
    } else {
      setSelectedDetectors(
        selectedDetectors.filter((id) => id !== detector.sensorSettingId)
      );
    }
  };

  const detectorsIds = detectors.map(
    (detector: IDetectorModel) => detector.sensorSettingId
  );

  const existDetectors = detectors.length > 0;

  const contactDetails = t("translation:SensorData.export.contactDetails")
    + ' ' + appStore?.userHistory?.firstName + ', '
    + appStore?.userHistory?.lastName + ', '
    + appStore?.userHistory?.email;

  const exportPDF = () => {
    try {
      if (detectors.length > 0)
        exportDetectorsPDF(detectors, true, contactDetails);
    } catch (e) {
      console.log(e);
    }
  };

  const exportExcel = () => {
    try {
      if (detectors.length > 0)
        exportDetectorsExcel(detectors, true);
    } catch (e) {
      console.log(e);
    }
  };

  const getPinCodeStr = (detector: IDetectorModel) => {
    if (detector.pinCode) {
      return detector.pinCode;
    }
    return t("translation:Templates_Mytemplates.details.noPinCode");
  }

  const keyPress = (e: any) => {
      if(e.keyCode == 13){
        getDetector(searchedValue);
      }
  }

  useEffect(() => {
    let macSearch = query.get("mac");
    if (macSearch !== null) {
      setSearchedValue(macSearch);
      getDetector(macSearch);
    }
  }, []);

  return (
    <>
    <Modal open={isFetching}>
      <div style={{ top: '50%'}}>
        {
            isFetching
            &&
            <LoadingSpinner />
        }
        </div>
    </Modal>
      <Modal open={openExportModal} onClose={handleCloseExportModal}>
        <Grid container direction="column" className={classes.modalExportCard}>
          <ExportModal
            title={t("translation:exportModal.templateTitle")}
            closeModal={handleCloseExportModal}
            exportPDF={exportPDF}
            exportExcel={exportExcel}
          />
        </Grid>
      </Modal>
      <Modal open={openAddFavoriteModal} onClose={handleCloseAddFavoriteModal}>
        <Grid container className={classes.addTemplateCard}>
          <AddTemplate
            closeModal={handleCloseAddFavoriteModal}
            setFavoriteName={setFavoriteName}
            favoriteName={favoriteName}
            saveTemplate={createTemplate}
          />
        </Grid>
      </Modal>
      <Modal open={openDetailsModal} onClose={handleCloseDetailsModal}>
        <Grid
          container
          direction="column"
          className={classes.detectorDetailsContainer}
        >
          {detector !== undefined && (
            <DetectorDetails
              detector={detector}
              closeModal={handleCloseDetailsModal}
              openExportModal={handleOpenExportModal}
            />
          )}
        </Grid>
      </Modal>
      <Grid container direction="column" className={classes.detectorContainer}>
        <DetectorHeader />
        <Grid
          item
          className={
            existDetectors
              ? classes.actionsBar
              : `${classes.actionsBar} ${classes.endAlign}`
          }
        >
          {existDetectors && (
            <>
              <Export
                openExportModal={handleOpenExportModal}
                closeModal={handleCloseExportModal}
              />
            </>
          )}
          <div
            className={
              existDetectors
                ? classes.searchContainer
                : `${classes.searchContainer} ${classes.endAlign}`
            }
          >
            <text className={classes.macAddressSuffix}>{macAddressSuffix}</text>
            <InputBase
              value={searchedValue}
              inputProps={{ "aria-label": "search google maps" }}
              inputComponent={TextMaskCustom as any}
              onChange={(e) => setSearchedValue(e.target.value)}
              onFocus={() => setSearchedValue(" ")}
              className={typoClasses.paragraphPrimary}
              onKeyDown={(e) => keyPress(e)}
            />
            <IconButton
              onClick={() => getDetector()}
              className={classes.iconButton}
              aria-label="search"
            >
              <img src="/img/search.svg" alt="search" />
            </IconButton>
          </div>
        </Grid>
        {existDetectors || error ? (
          <Grid item className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <colgroup>
                <col style={{ width: '5%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
              </colgroup>
              <TableHead>
                <TableRow className={classes.headingRow}>
                  <TableCell className={classes.columnName}></TableCell>
                  <TableCell className={classes.columnName}>
                    {t(
                      "translation:Detectors_search.tableHeadings.detectorType"
                    )}
                  </TableCell>
                  <TableCell className={classes.columnName}>
                    {t(
                      "translation:Detectors_search.tableHeadings.detectorName"
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.columnName}
                    align={cellAlignment}
                  >
                    {t("translation:Detectors_search.tableHeadings.MACaddress")}
                  </TableCell>
                  <TableCell
                    className={classes.columnName}
                    align={cellAlignment}
                  >
                    {t(
                      "translation:Detectors_search.tableHeadings.projectName"
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.columnName}
                    align={cellAlignment}
                  >
                    {t("translation:Detectors_search.tableHeadings.pinCode")}
                  </TableCell>
                  <TableCell
                    className={classes.columnName}
                    align={"right"}
                  >
                    {t("translation:Detectors_search.tableHeadings.lastUpload")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {existDetectors ? (
                  detectors.map((detector: IDetectorModel, index) => (
                    <TableRow className={classes.rowContent} key={index}>
                      <TableCell
                        align="center"
                        className={classes.star}
                        onClick={() => handleOpenAddFavoriteModal(detector)}
                      >
                        <img
                          src={`/img/${detector.isFavorite ? "removeTemplateBlack" : "addTemplateBlack"
                            }.svg`}
                          alt="make favorite"
                        />
                      </TableCell>
                      <TableCell
                        className={classes.detectorType}
                        onClick={() => handleOpenDetailsModal(detector)}
                      >
                        {getDetectorTypeFormatted(detector, t)}
                      </TableCell>
                      <TableCell
                        className={classes.content}
                        onClick={() => handleOpenDetailsModal(detector)}
                      >
                        {detector.customName}
                      </TableCell>
                      <TableCell
                        className={classes.content}
                        onClick={() => handleOpenDetailsModal(detector)}
                      >
                        {detector.macAddress}
                      </TableCell>
                      <TableCell
                        className={classes.content}
                        align={cellAlignment}
                        onClick={() => handleOpenDetailsModal(detector)}
                      >
                        {detector.projectName}
                      </TableCell>
                      <TableCell
                        className={classes.content}
                        align={cellAlignment}
                        onClick={() => handleOpenDetailsModal(detector)}
                      >
                        {getPinCodeStr(detector)}
                      </TableCell>
                      <TableCell
                        className={classes.content}
                        align={"right"}
                        onClick={() => handleOpenDetailsModal(detector)}
                      >
                        {moment
                          .tz(detector.lastUpload, timeZone)
                          .format("DD.MM.YYYY")}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={7}
                        className={classes.errorContent}
                      >
                        <img src="/img/close.svg" alt="close" />
                        <Typography
                          component="h3"
                          className={typoClasses.headerThreeBlack}
                        >
                          {t(`translation:Detectors_search.errorStatus.title`)}
                        </Typography>
                        <Typography className={typoClasses.paragraphPrimary}>
                          {t(
                            `translation:Detectors_search.errorStatus.content`
                          )}
                        </Typography>
                        <Typography className={typoClasses.paragraphPrimary}>
                          {t(
                            `translation:Detectors_search.errorStatus.secondaryContent`
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* <TableRow className={classes.rowContent}>
                      <TableCell colSpan={5}>Wrong MAC address</TableCell>
                    </TableRow>
                    <TableRow className={classes.rowContent}>
                      <TableCell colSpan={5}>
                        The MAC address you entered cannot be found. Please
                        check the address and try again.
                      </TableCell>
                    </TableRow> */}
                  </>
                )}
              </TableBody>
            </Table>
          </Grid>
        ) : (
          <DetectorsNoSearch />
        )}
      </Grid>

      {showNote && existDetectors && (
        <Grid item className={classes.infoFavorite}>
          <img src="/img/addTemplate.svg" alt="favorite" />
          <div>
            <Typography component="h3" className={typoClasses.headerThreeLight}>
              {t("translation:Detectors_search.infoFavorite.infoTitle")}
            </Typography>
            <Typography component="span" className={classes.infoContent}>
              {t("translation:Detectors_search.infoFavorite.infoContent")}
            </Typography>
          </div>
          <Button
            className={`${classes.buttonWraper} ${buttonStyles.lightButtonNoBorder}`}
            onClick={handleNoteInfo}
          >
            {t("translation:common.closeButton")}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default Detector;
