import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Button,
  Checkbox,
  Collapse,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
  InputLabel,
  InputBase,
  IconButton
} from "@material-ui/core";
import Export from "../../common/components/Actions/Export";
import ExportModal from "../../common/components/ExportModal";
import Delete from "../../common/components/Actions/Delete";
import CheckAll from "../../common/components/Actions/CheckAll";
import {
  getProjectById,
  updateProject,
} from "../../data-services/Projects/ProjectsService";
import { IProject } from "../../models/Project";
import { ISensorSetting } from "../../models/SensorSetting";
import ChevronUp from "../../common/components/ChevronUp";
import ChevronDown from "../../common/components/ChevronDown";
import CheckedBoldBox from "../../common/components/CheckedBoldBox";
import UncheckedboxSVG from "../../common/components/UncheckedBox";
import Pencil from "../../common/components/Pencil";
import SaveChanges from "./Components/SaveChanges";
import { exportDetectorsPDF } from "../../common/components/Export/ExportPDF";
import DeleteModal from "../../common/components/DeleteModal";
import { removeSensorSettings } from "../../data-services/common/SensorSettingsService";
import { exportDetectorsExcel } from "../../common/components/Export/ExportExcel";
import {GetProjectDetails } from "../../common/components/SensorData/GetProjectDetails";
import ProjectDetails from "./Components/ProjectDetails";
import useButtonStyles from "../../common/styles/buttonStyles";
import useTypoClasses from "../../common/styles/typography";
import useStyles from "./Project.style";
import { getDetectorTypeFormatted } from "../../data-services/Templates/TemplateMapper";
import useNothingIsSelectedModalStyles from "../../common/styles/nothingIsSelectedModalGeneralStyle";
import NothingSelectedModal from "../../common/components/NothingSelectedModal";
import { useAuth } from "../../context/context";
import { getEnvFile } from "env-cmd/dist/get-env-vars";

const Project: React.FC = () => {
  const classes = useStyles();
  const nothingIsSelectedModalStyles = useNothingIsSelectedModalStyles();
  const buttonClasses = useButtonStyles();
  const typoClasses = useTypoClasses();
  const { t } = useTranslation(["translation"]);
  const authStore = useAuth();
  const [selectedSettings, setSelectedSettings] = useState<string[]>([]);
  const [project, setProject] = useState<IProject>();
  const [setting, setSetting] = useState<ISensorSetting | undefined>(undefined);
  const [newProjectName, setNewProjectName] = useState("");
  const [openDali2, setOpenDali2] = useState(true);
  const [open230V, setOpen230V] = useState(true);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isProjectSaved, setIsProjectSaved] = useState(true);
  let { id } = useParams();

  const [openExportModal, setOpenExportModal] = useState(false);
  const [
    openExportNothingIsSelectedModal,
    setOpenExportNothingIsSelectedModal,
  ] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [
    openDeleteNothingIsSelectedModal,
    setOpenDeleteNothingIsSelectedModal,
  ] = useState(false);

  const [filterKey, setFilterKey] = useState("");

  const contactDetails =
    t("translation:SensorData.export.contactDetails") +
    " " +
    authStore?.userHistory?.firstName +
    ", " +
    authStore?.userHistory?.lastName +
    ", " +
    authStore?.userHistory?.email;

  const handleOpenExportModal = () => {
    if (selectedSettings.length > 0 || setting) {
      setOpenExportModal(true);
    } else {
      setOpenExportNothingIsSelectedModal(true);
    }
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
    setOpenExportNothingIsSelectedModal(false);

    setSetting(undefined);
  };

  const handleOpenDeleteModal = () => {
    if (selectedSettings.length > 0) {
      setOpenDeleteModal(true);
    } else {
      setOpenDeleteNothingIsSelectedModal(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setOpenDeleteNothingIsSelectedModal(false);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const [openSaveModal, setOpenSaveModal] = useState(false);

  const handleOpenSaveModal = () => {
    if (newProjectName.length > 0) {
      setOpenSaveModal(true);
    }
  };

  const handleCloseSaveModal = () => {
    setOpenSaveModal(false);
  };

  const handleCancelSaveModal = () => {
    setOpenSaveModal(false);
    if (project) {
      setNewProjectName("");
    }
  };

  const handleExpandDali2 = () => {
    setOpenDali2(!openDali2);
  };

  const handleExpand230V = () => {
    setOpen230V(!open230V);
  };

  const handleCloseDetailsModal = (singleExport = false) => {
    setOpenDetailsModal(false);
    if (!singleExport) {
      setSetting(undefined);
    }
  };

  const handleEditProject = () => {
    project &&
      editProject(project.id).then((response) => {
        if (response.status === "success") {
          handleCloseEditModal();
          setIsProjectSaved(true);
        } else {
          setIsProjectSaved(false);
        }
      });
  };

  const handleExportProjectPDF = () => {
    try {
      if (project !== undefined) {
        const selectedDetectorsExport = project.sensorSettings.filter(
          (detector) => selectedSettings.includes(detector.id)
        );
        if (setting) {
          exportDetectorsPDF([setting], true, contactDetails, project.name);
        } else {
          exportDetectorsPDF(
            selectedDetectorsExport,
            true,
            contactDetails,
            project.name
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleExportProjectExcel = () => {
    try {
      if (project !== undefined) {
        const selectedDetectorsExport = project.sensorSettings.filter(
          (detector) => selectedSettings.includes(detector.id)
        );
        if (setting) {
          exportDetectorsExcel([setting], true, project.name);
        } else {
          exportDetectorsExcel(selectedDetectorsExport, true, project.name);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    setting: ISensorSetting
  ) => {
    if (event.target.checked) {
      setSelectedSettings(selectedSettings.concat(setting.id));
    } else {
      setSelectedSettings(selectedSettings.filter((id) => id !== setting.id));
    }
  };

  const fetchProject = async () => {
    if (id) {
      const { data } = await getProjectById(
        id,
        authStore?.getImpersonatedUserEmail() || null
      );

      setProject({ ...data });
    }
  };

  const filterProjects = (newFilter?:string) => {
    const key = newFilter?.toUpperCase() || filterKey.toUpperCase();
    const newSettings = project?.sensorSettings.map((setting) =>
      setting.sensorInformation.detectorShortDescription.includes(key) ||
      (setting.customName && setting.customName.toUpperCase().includes(key)) ||
      setting.sensorInformation.detectorType.includes(key)
        ? { ...setting, isVisible: true }
        : { ...setting, isVisible: false }
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setProject({ ...project, sensorSettings: newSettings });

    if (newFilter !== undefined) {
      setFilterKey(newFilter);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  const filterSensors = (c: ISensorSetting, type: string) =>
    c.sensorInformation.detectorFamily.toLowerCase().includes(type);

  const editProject = async (id: string): Promise<{ status: string }> => {
    const { status } = await updateProject(
      { id, name: newProjectName },
      authStore?.getImpersonatedUserEmail() || null
    );
    if (status === "success") {
      fetchProject();
      return { status };
    }
    return { status: "error" };
  };

  const deleteSettings = async (sensorSettingIds: string[]): Promise<any> => {
    try {
      const { status } = await removeSensorSettings(sensorSettingIds);
      if (status === "success") {
        fetchProject();
      }
    } catch (error) {
      return "error";
    }
  };

  const handleClickSettings = (setting: ISensorSetting) => {
    setOpenDetailsModal(true);
    setSetting(setting);
  };

  const cutUnwanted = (str:string) => {
    const searchStr = "BLE";
    const index = str.indexOf(searchStr);

    if (index !== -1) {
      return str.slice(0, index + searchStr.length -3);
    }
   return str.replace(/\x00$/, "");
  }
  const decodeVersion = (version: string): string => {
    try {
      const decoded = JSON.parse(version);
      return cutUnwanted(atob(decoded.SoftwareVersions))
    } catch (e) {
      console.log(e);
      return "unknown";
    }
  };


  const ListFWItem = ({type,version}:any) => {
    return (
      <Typography>
        <span className="type">{type}: </span>
        {version}
      </Typography>
    );
  }

  const ListFWGroup = ({detector}:any) => {

    const  {sensorSwVersionModel, sensorActorVersionModel} = detector;

    return (
      <div
        className={classes.projectDetailsFW}
        onClick={() => handleClickSettings(detector)}
      >
        <ListFWItem
          type="Sensor"
          version={
            sensorSwVersionModel === null
              ? "unknown"
              : decodeVersion(sensorSwVersionModel)
          }
        />
        {sensorActorVersionModel && (
          <ListFWItem
            type="Actor"
            version={decodeVersion(sensorActorVersionModel)}
          />
        )}
      </div>
    );

  };

  return (
    <>
      <Modal
        open={
          openExportModal ? openExportModal : openExportNothingIsSelectedModal
        }
        onClose={handleCloseExportModal}
      >
        <Grid
          container
          direction="column"
          className={
            openExportModal
              ? classes.modalExportCard
              : nothingIsSelectedModalStyles.style
          }
        >
          {openExportModal && (
            <ExportModal
              title={t("translation:exportModal.projectsTitle")}
              closeModal={handleCloseExportModal}
              exportPDF={handleExportProjectPDF}
              exportExcel={handleExportProjectExcel}
            />
          )}
          {openExportNothingIsSelectedModal && (
            <NothingSelectedModal
              title={t(
                "translation:Projects_Myprojects.deleteProjects.titleNothingIsSelected"
              )}
              content={t(
                "translation:Projects_Myprojects.exportSettingsNothingIsSelected"
              )}
              closeModal={handleCloseExportModal}
            />
          )}
        </Grid>
      </Modal>
      <Modal
        open={
          openDeleteModal ? openDeleteModal : openDeleteNothingIsSelectedModal
        }
        onClose={handleCloseDeleteModal}
      >
        <Grid
          container
          direction="column"
          className={
            openDeleteModal
              ? classes.modalDeleteCard
              : nothingIsSelectedModalStyles.style
          }
        >
          {openDeleteModal && (
            <DeleteModal
              title={t("translation:Projects_View.deleteModal.title")}
              content={t("translation:Projects_View.deleteModal.content")}
              deleteButtonName={t("translation:common.delete")}
              remove={() => {
                deleteSettings(selectedSettings);
                handleCloseDeleteModal();
              }}
              closeModal={handleCloseDeleteModal}
            />
          )}
          {openDeleteNothingIsSelectedModal && (
            <NothingSelectedModal
              title={t(
                "translation:Projects_Myprojects.deleteProjects.titleNothingIsSelected"
              )}
              content={t(
                "translation:Projects_Myprojects.deleteSettingsNothingIsSelected"
              )}
              closeModal={handleCloseDeleteModal}
            />
          )}
        </Grid>
      </Modal>
      <Modal open={openSaveModal} onClose={handleCloseSaveModal}>
        <Grid container direction="column" className={classes.modalCard}>
          <SaveChanges
            cancel={handleCancelSaveModal}
            saveNewName={() => project && editProject(project.id)}
            closeModal={handleCloseSaveModal}
          />
        </Grid>
      </Modal>
      <Modal open={openDetailsModal} onClose={() => handleCloseDetailsModal()}>
        <Grid
          container
          direction="column"
          className={classes.projectDetailsContainer}
        >
          {setting !== undefined && (
            <ProjectDetails
              setting={setting}
              closeModal={() => handleCloseDetailsModal(true)}
              openExportModal={handleOpenExportModal}
            />
          )}
        </Grid>
      </Modal>
      <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <Grid
          container
          direction="column"
          className={classes.projectDetailsContainer}
        >
          <Typography className={typoClasses.headerThreeBlack} component="h3">
            {t("translation:Projects_View.renameProject")}
          </Typography>
          <Grid
            item
            className={`${classes.verticalInputSpacing} ${
              !isProjectSaved ? classes.errorBorder : ""
            } `}
          >
            <InputLabel className={classes.inputLabel} required={true}>
              {t("translation:common.modals.projectName")}
            </InputLabel>
            <Input
              defaultValue={project?.name || ""}
              inputProps={{ "aria-label": "description" }}
              onChange={(e) => {
                setNewProjectName(e.target.value);
              }}
              disableUnderline={true}
              className={classes.inputName}
            />
          </Grid>
          <Grid
            item
            className={`${classes.verticalInputSpacing} ${
              classes.buttonsContainer
            } ${!isProjectSaved ? classes.errorBorder : ""}`}
          >
            <Button
              className={buttonClasses.lightButton}
              onClick={() => {
                setNewProjectName("");
                handleCloseEditModal();
              }}
            >
              {t("translation:common.cancel")}
            </Button>
            <Button
              onClick={handleEditProject}
              disabled={newProjectName.length === 0}
              className={
                newProjectName
                  ? buttonClasses.primaryButton
                  : buttonClasses.inactiveButton
              }
            >
              {t("translation:common.saveChanges")}
            </Button>
            {!isProjectSaved && (
              <Typography className={classes.error} component="p">
                {t("translation:common.modals.usedProjectName")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Modal>
      {project && (
        <Grid container className={classes.projectViewWrapper}>
          <Grid item>
            <div className={classes.headerWrapper}>
              <Typography className={classes.heading} component="h1">
                {`${t("translation:Projects_View.projectsName")} ${
                  project.name
                }`}
              </Typography>
              <span
                className={classes.editName}
                onClick={() => {
                  setOpenEditModal(true);
                  setIsProjectSaved(true);
                }}
              >
                <Pencil />
              </span>
            </div>
            <Typography className={classes.content}>
              {t("translation:Projects_View.aboutPage")}
            </Typography>
          </Grid>
          <Grid item className={classes.actionsWrapper}>
            <div className={classes.verticalInputSpacing}>
              <CheckAll
                allItemIds={project.sensorSettings.map(
                  (sensor: ISensorSetting) => sensor.id
                )}
                selectedItemIds={selectedSettings}
                setSelectedItemsIds={setSelectedSettings}
              />
              <Export openExportModal={handleOpenExportModal} />
              <Delete openDeleteModal={handleOpenDeleteModal} />
            </div>
            <div className="searchBox">
              <InputBase
                value={filterKey}
                placeholder={t(
                  "translation:Projects_View.searchForDetectorNameOrType"
                )}
                fullWidth
                //inputProps={{ "aria-label": "" }}
                onChange={(e) => filterProjects(e.target.value)}
                className={typoClasses.paragraphPrimary}
                onKeyDown={(e) => e.key === "Enter" && filterProjects()}
              />
              <IconButton
                onClick={() => filterProjects()}
                //className={classes.iconButton}
                aria-label="search"
              >
                <img src="/img/search.svg" alt="search" />
              </IconButton>
            </div>
          </Grid>
          <Grid className={classes.projectDetailsTable}>
            <List
              style={{ minWidth: "800px" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.root}
            >
              <ListItem
                button
                onClick={handleExpandDali2}
                className={classes.infoItem}
              >
                <ListItemIcon className={classes.iconAlign}>
                  {openDali2 ? <ChevronUp /> : <ChevronDown />}
                </ListItemIcon>
                <div className={classes.alignContent}>
                  <ListItemText
                    className={classes.listItemText}
                    style={{ flexBasis: "200px" }}
                    primary={`DALI-2 detectors: [${
                      project.sensorSettings.filter((c) =>
                        filterSensors(c, "dali")
                      ).length
                    }]`}
                  />
                  <ListItemText
                    className={classes.titleHeader}
                    style={{ flexBasis: "150px" }}
                    primary={t(
                      "translation:Detectors_search.tableHeadings.detectorName"
                    )}
                  />
                  <ListItemText
                    className={classes.titleHeader}
                    style={{ flexBasis: "340px" }}
                    primary="Details"
                  />
                  <ListItemText
                    className={classes.titleHeader}
                    style={{ flexBasis: "230px" }}
                    primary="Firmware"
                  />
                </div>
              </ListItem>
              {project.sensorSettings
                .filter((c) => filterSensors(c, "dali"))
                .filter((c) => !("isVisible" in c) || c.isVisible === true)
                .map((setting, index) => {
                  let sensorInfo = setting.sensorInformation;
                  return (
                    <Collapse
                      in={openDali2}
                      timeout="auto"
                      unmountOnExit
                      key={index}
                    >
                      <ListItem>
                        <ListItemIcon className={classes.checkAlign}>
                          <Checkbox
                            checked={selectedSettings.some(
                              (id) => id === setting.id
                            )}
                            onChange={(event) => handleCheck(event, setting)}
                            checkedIcon={<CheckedBoldBox />}
                            inputProps={{ "aria-label": "checkbox" }}
                            icon={<UncheckedboxSVG />}
                            disableRipple
                          />
                        </ListItemIcon>
                        <div className={classes.alignContent}>
                          <ListItemText
                            className={classes.listContent}
                            style={{ flexBasis: "200px" }}
                            onClick={() => handleClickSettings(setting)}
                            primary={getDetectorTypeFormatted(setting, t)}
                          />
                          <ListItemText
                            className={classes.listContent}
                            style={{ flexBasis: "150px" }}
                            onClick={() => handleClickSettings(setting)}
                            primary={setting.customName || "-"}
                          />
                          <ListItemText
                            className={classes.projectDetails}
                            style={{ flexBasis: "280px" }}
                            onClick={() => handleClickSettings(setting)}
                            primary={GetProjectDetails(setting)}
                          />
                          <ListFWGroup detector={setting} />
                        </div>
                      </ListItem>
                    </Collapse>
                  );
                })}

              <ListItem
                button
                onClick={handleExpand230V}
                className={classes.infoItem}
              >
                <ListItemIcon className={classes.iconAlign}>
                  {open230V ? <ChevronUp /> : <ChevronDown />}
                </ListItemIcon>
                <div className={classes.alignContent}>
                  <ListItemText
                    className={classes.listItemText}
                    style={{ flexBasis: "200px" }}
                    primary={`230V: [${
                      project &&
                      project.sensorSettings.filter((c) =>
                        filterSensors(c, "230")
                      ).length
                    }]`}
                  />
                  <ListItemText
                    className={classes.titleHeader}
                    style={{ flexBasis: "150px" }}
                    primary={t(
                      "translation:Detectors_search.tableHeadings.detectorName"
                    )}
                  />
                  <ListItemText
                    className={classes.titleHeader}
                    style={{ flexBasis: "340px" }}
                    primary="Details"
                  />
                  <ListItemText
                    className={classes.titleHeader}
                    style={{ flexBasis: "230px" }}
                    primary="Firmware"
                  />
                </div>
              </ListItem>
              {project.sensorSettings
                .filter((c) => filterSensors(c, "230"))
                .filter((c) => !("isVisible" in c) || c.isVisible === true)
                .map((setting, index) => {
                  let sensorInfo = setting.sensorInformation;
                  return (
                    <Collapse
                      in={open230V}
                      timeout="auto"
                      unmountOnExit
                      key={index}
                    >
                      <ListItem>
                        <ListItemIcon className={classes.checkAlign}>
                          <Checkbox
                            checked={selectedSettings.some(
                              (id) => id === setting.id
                            )}
                            onChange={(event) => handleCheck(event, setting)}
                            checkedIcon={<CheckedBoldBox />}
                            inputProps={{ "aria-label": "checkbox" }}
                            icon={<UncheckedboxSVG />}
                            disableRipple
                          />
                        </ListItemIcon>
                        <div className={classes.alignContent}>
                          <ListItemText
                            className={classes.listContent}
                            style={{ flexBasis: "200px" }}
                            onClick={() => handleClickSettings(setting)}
                            primary={getDetectorTypeFormatted(setting, t)}
                          />
                          <ListItemText
                            className={classes.listContent}
                            style={{ flexBasis: "150px" }}
                            onClick={() => handleClickSettings(setting)}
                            primary={setting.customName || "-"}
                          />
                          <ListItemText
                            className={classes.projectDetails}
                            style={{ flexBasis: "280px" }}
                            onClick={() => handleClickSettings(setting)}
                            primary={GetProjectDetails(setting)}
                          />
                          <ListFWGroup detector={setting} />
                        </div>
                      </ListItem>
                    </Collapse>
                  );
                })}
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Project;
